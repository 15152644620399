/**
 * TODO: rename to CountryStatus
 */
export enum AssessmentStatus {
  notStarted = 'notStarted',
  editing = 'editing',
  review = 'review',
  approval = 'approval',
  accepted = 'accepted',
  changing = 'changing',
}
